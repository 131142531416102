import { basicFonts, premiumFonts } from '../../../objects/Document'
import { Fonts } from '../../../repos/Fonts'
import { default_font, default_color, STANDARD_CURSIVE, STANDARD_PRINT } from '../../helpers/documentHelpers'
import { hasNonIconImages, hasPremiumIcon, hasPremiumNumbering, hasPremiumColor } from '../../../helpers/jsonObjectHelper'
import BaseModule from '../base'

export default class PremiumModule extends BaseModule {
    state() {
        return {
            ...super.state(),
            premium_fonts: 'one',
        }
    }

    getters() {
        return {
            ...super.getters(),
            handwritingFonts: (state, getters) => {
                return getters.documentItems
                    .filter((i) => i.type === 'handwriting')
                    .flatMap((hw) => [hw.data[`hw_font_${hw.data.cursive_style}`]])
            },
            getPremiumFonts: (state, getters, rootState) => {
                const premiumFontType = state.premium_fonts

                switch (premiumFontType) {
                    case 'one':
                        const free_fonts = [default_font, STANDARD_PRINT, STANDARD_CURSIVE]
                        return [
                            ...Fonts.fonts.filter((font) => !free_fonts.includes(font)),
                            ...Fonts.hwPrintFonts.filter((font) => !free_fonts.includes(font)),
                            ...Fonts.hwCursiveFonts.filter((font) => !free_fonts.includes(font)),
                        ]

                    case 'basic':
                        return Fonts.fonts.filter((font) => !basicFonts.includes(font))

                    case 'most':
                        return premiumFonts

                    case 'all':
                        return []

                    default:
                        return []
                }
            },
            getPremiumFeatures: (state, getters, rootState, rootGetters) => {
                const documentEntityType = rootGetters['document/entityType']
                const documentType = getters.getDocumentType
                const wordbank = getters.wordbank
                const style = getters.worksheetStyle
                const wordbankStyle = getters.wordbankStyle
                const instruction = getters.documentInstructions
                const handwritingFonts = getters.handwritingFonts
                const images = rootGetters[`document/${documentEntityType}AllImages`] ?? []
                let premiumFonts = getters.getPremiumFonts
                const premiumFeatures = []

                // Numbers
                if (documentEntityType == 'worksheet') {
                    const worksheet = rootGetters['document/worksheet']
                    let hasPremiumNumbers = false
                    for (let i in worksheet.body.items) {
                        if (hasPremiumNumbers) continue

                        if (worksheet.body.items[i].type === 'matching') {
                            hasPremiumNumbers =
                                hasPremiumNumbering(worksheet.body.items[i].data?.settings.left_column_format) ||
                                hasPremiumNumbering(worksheet.body.items[i].data?.settings.right_column_format)
                            continue
                        }

                        if (['checklist', 'multiple_choice'].includes(worksheet.body.items[i].type)) {
                            hasPremiumNumbers =
                                hasPremiumNumbering(worksheet.body.items[i].data?.settings?.format) ||
                                hasPremiumNumbering(worksheet.body.items[i].numbering?.format)
                            continue
                        }

                        hasPremiumNumbers = hasPremiumNumbering(worksheet.body.items[i].numbering?.format)
                    }

                    if (hasPremiumNumbers) {
                        premiumFeatures.push('numbering')
                    }
                }

                // Images
                if (images.length > 0 && hasNonIconImages(images)) {
                    premiumFeatures.push('image_uploading')
                }

                // Images
                if (hasPremiumIcon(images)) {
                    premiumFeatures.push('premium_icons')
                }

                // Fonts
                if (premiumFonts) {
                    if (
                        (state.title_visible && premiumFonts.indexOf(state.title_font) > -1) ||
                        (state.student_info_visible && premiumFonts.indexOf(state.student_info_font) > -1) ||
                        (instruction && instruction.visible && premiumFonts.indexOf(instruction.style.font) > -1) ||
                        (documentType != 'handwriting' && premiumFonts.indexOf(style.font) > -1) ||
                        (wordbank?.visible &&
                            (['fill_in_the_blank', 'word_scramble'].includes(documentType) ||
                                state.entity_type === 'bingo') &&
                            premiumFonts.indexOf(wordbankStyle.font) > -1)
                    ) {
                        //we have premium fonts and they're in use
                        premiumFeatures.push('font')
                    }
                }

                // Handwriting Fonts
                if (
                    premiumFonts &&
                    handwritingFonts.length &&
                    handwritingFonts.filter((p) => premiumFonts.includes(p)).length > 0
                ) {
                    //we have premium fonts and they're in use
                    premiumFeatures.push('hw_font')
                }

                //borders
                if (
                    (instruction && instruction.visible && instruction.style.border_style != 'solid') ||
                    (wordbankStyle?.visible &&
                        wordbankStyle?.border_style != 'solid' &&
                        ['fill_in_the_blank', 'word_scramble'].includes(documentType))
                ) {
                    premiumFeatures.push('border') //we have premium fonts and they're in use
                }

                // Number format cannot be ) or FALSE
                if (style.number_format > 2 && documentType != 'handwriting' && documentType != 'bingo') {
                    premiumFeatures.push('number')
                }

                //Check Bingo card count
                if (documentType == 'bingo' && state.bingo.num_of_cards > rootState.user.ab_tests['premium-bingo-cards']) {
                    premiumFeatures.push('card_count')
                }

                if (documentEntityType === 'worksheet') {
                    //fill-in-the-blank blank style
                    const worksheetItems = state.worksheet.body.items
                    for (let wItem of worksheetItems) {
                        if (
                            wItem.type == 'fill_in_the_blank' &&
                            wItem.data.answer_type === 'letter_blanks' &&
                            wItem.data.blank_style != 'underline'
                        ) {
                            premiumFeatures.push('fill_in_the_blank_style')
                        }
                    }
                }

                // Colors (worksheet)
                if (documentEntityType === 'worksheet') {
                    if (hasPremiumColor({ ...state }, default_color)) {
                        premiumFeatures.push('color')
                    }
                }

                if (!rootState.subscription.plan) {
                    if (state.is_private == '1') {
                        premiumFeatures.push('private')
                    }
                } else {
                    if (!getters.hasWorksheetStandardPlan && !getters.hasWorksheetPrivatePlan && state.is_private == '1') {
                        premiumFeatures.push('private')
                    }
                }

                if (rootState.subscription.plan && getters.hasWorksheetStandardPlan) {
                    return []
                }
                return [...new Set(premiumFeatures)]
            },
            premiumFont: (state) => state.premium_fonts,
            hasWorksheetStandardPlan: (state, getters, rootState) => {
                return (
                    rootState.subscription.plan?.includes('Unlimited Worksheets') ||
                    rootState.subscription.plan?.includes('Standard')
                )
            },
            hasWorksheetPrivatePlan: (state, getters, rootState) => {
                return (
                    rootState.subscription.plan?.includes('Private Worksheets') ||
                    rootState.subscription.plan?.includes('Basic')
                )
            },
        }
    }

    actions() {
        return {
            ...super.actions(),
            getPremiumFeaturesByDocument({ rootState, getters, state }, document) {
                const premiumFeatures = []
                const documentEntityType = document.entity_type
                const handwritingFonts = document?.body?.items
                    .filter((i) => i.type === 'handwriting')
                    .flatMap((hw) => [hw.data[`hw_font_${hw.data.cursive_style}`]])
                const premiumFonts = getters.getPremiumFonts
                // const style = document.worksheetStyle
                let instructions,
                    wordbanks,
                    documentItems = []

                // Numbers
                if (documentEntityType == 'worksheet') {
                    let hasPremiumNumbers = false
                    documentItems = document.entity.body.items
                    for (let i in documentItems) {
                        hasPremiumNumbers = hasPremiumNumbering(documentItems[i].numbering?.format)
                    }

                    if (hasPremiumNumbers) {
                        premiumFeatures.push('numbering')
                    }

                    instructions = documentItems.filter((docItem) => docItem.type === 'instruction')

                    wordbanks = documentItems.filter((docItem) => docItem.type === 'wordbank')
                } else {
                    documentItems = document.entity.content.items
                    instructions = [document.entity.instruction]
                    wordbanks = [document.entity.content.wordbank]
                }

                const instructionFonts = instructions.filter((i) => !i.hide).map((i) => i.style.font)
                const wordbankFonts = wordbanks.map((i) => i?.style?.font)

                // Fonts
                if (premiumFonts) {
                    if (
                        (document.title_visible && premiumFonts.indexOf(document.title_font) > -1) ||
                        (document.student_info_visible && premiumFonts.indexOf(document.student_info_font) > -1) ||
                        (instructionFonts.length && instructionFonts.some((iFont) => premiumFonts.includes(iFont))) ||
                        (wordbankFonts?.length && wordbankFonts.some((iFont) => premiumFonts.includes(iFont)))
                    ) {
                        //we have premium fonts and they're in use
                        premiumFeatures.push('font')
                    }
                }

                // Handwriting Fonts
                if (
                    premiumFonts &&
                    handwritingFonts &&
                    handwritingFonts.length &&
                    handwritingFonts.filter((p) => premiumFonts.includes(p)).length > 0
                ) {
                    //we have premium fonts and they're in use
                    premiumFeatures.push('hw_font')
                }

                //borders
                const borderStyles = [...instructions, ...wordbanks].map((i) => i?.style?.border_style)
                if (borderStyles.some((borderStyle) => borderStyle && borderStyle !== 'solid')) {
                    premiumFeatures.push('border') //we have premium fonts and they're in use
                }

                // Number format cannot be ) or FALSE
                if (document.number_format > 2 && documentType != 'handwriting' && documentType != 'bingo') {
                    premiumFeatures.push('number')
                }

                //Check Bingo card count
                if (
                    documentEntityType == 'bingo' &&
                    document.num_of_cards > rootState.user.ab_tests['premium-bingo-cards']
                ) {
                    premiumFeatures.push('card_count')
                }

                if (documentEntityType === 'worksheet') {
                    //fill-in-the-blank blank style and handwriting lines color
                    const worksheetItems = state.worksheet.body.items
                    for (let wItem of worksheetItems) {
                        if (
                            wItem.type == 'fill_in_the_blank' &&
                            wItem.data.answer_type === 'letter_blanks' &&
                            wItem.data.blank_style != 'underline'
                        ) {
                            premiumFeatures.push('fill_in_the_blank_style')
                        }
                    }
                }

                // Colors (worksheet)
                if (documentEntityType === 'worksheet') {
                    if (hasPremiumColor({ ...state }, default_color)) {
                        premiumFeatures.push('color')
                    }
                }

                if (documentEntityType !== 'worksheet') {
                    return []
                }

                if (!rootState.subscription.plan) {
                    if (document.is_private == 1) {
                        premiumFeatures.push('private')
                    }
                } else {
                    if (!getters.hasWorksheetStandardPlan && !getters.hasWorksheetPrivatePlan && document.is_private == 1) {
                        premiumFeatures.push('private')
                    }
                }

                if (rootState.subscription.plan && getters.hasWorksheetStandardPlan) {
                    return []
                }
                return [...new Set(premiumFeatures)]
            },
        }
    }
}
